import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import { getOrders, getOrdersbyCenter } from "../../../redux/actions/order";
import { getOrdersbyStudy, updateOrder } from "../../../redux/actions/order";
import { store } from "../../../store";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import CSVDownloader from "../../ui/CSVDownloader";
import { isWeekend } from "date-fns";
import { showSuccessAlert } from "../../ui/utils/AlertUtils";
import CustomFilter from "../../table/CustomFilter";
import getUniqueOptions from "../../table/getUniqueOptions";
import { useTranslation } from "react-i18next";
import LocalizedDataGrid from "../../ui/datagrid/LocalizedDataGrid";
import FirstSupplyDialog from "../../ui/dialogs/FirstSupplyDialog";

const SPACING = 50;

export const ScreenOrders = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const orders = useSelector((state) => state.order.orders);
  const isLoading = useSelector((state) => state.order.isLoadingOrders);
  const studyId = location.state?.studyId;
  const centerId = location.state?.centerId;
  const centerNumber = location.state?.centerNumber;
  const studyCode = location.state?.studyCode;
  const cdlUser = useSelector(
    (state) => state.user.user.organization === "CDL"
  );
  const centerUser = useSelector(
    (state) => state.user.user.organization === "Center"
  );
  const [selectedRows, setSelectedRows] = React.useState([]);
  const today = new Date();
  const [FSDialogOpen, setFSDialogOpen] = React.useState(false);
  const [isFirstSupply, setIsFirstSupply] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState({});
  const updatedOrder = useSelector((state) => state.order.updatedOrder);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [carrierOptions, setCarrierOptions] = React.useState([]);
  const [studyCodeOptions, setStudyCodeOptions] = React.useState([]);
  const [centerNumberOptions, setCenterNumberOptions] = React.useState([]);
  const [ordersWithFormattedDates, setOrdersWithFormattedDates] =
    React.useState([]);
  const formattedDate = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()}`;
  let filename = `orders_${formattedDate}`;
  if (studyCode || centerNumber) {
    filename = `${studyCode ? studyCode + "_" : ""}${
      centerNumber ? centerNumber + "_" : ""
    }orders_${formattedDate}`;
  }

  const handleFSConfirm = () => {
    store.dispatch(updateOrder(selectedOrder.id, { isFirstSupply }));
    setFSDialogOpen(false);
    showSuccessAlert(t("orderUpdatedSuccessfully"));
  };

  function calculateProcessingTime(order) {
    if (!order.sendingDate || order.sendingDate === t("notSentYet")) {
      return "";
    }
    if (order.isFirstSupply) {
      return t("firstSupply");
    }
    const orderDate = moment(order.orderDate, "DD/MM/YYYY")
      .startOf("day")
      .toDate();
    const sendingDate = moment(order.sendingDate, "DD/MM/YYYY")
      .startOf("day")
      .toDate();

    // Check if the sendingDate is before the orderDate
    if (sendingDate < orderDate) {
      return t("notPossible");
    }
    let workingDays = 0;
    for (
      let day = new Date(orderDate);
      day <= sendingDate;
      day.setDate(day.getDate() + 1)
    ) {
      if (!isWeekend(day)) {
        workingDays++;
      }
    }
    return workingDays > 0 ? workingDays - 1 : 0;
  }

  const columns = [
    {
      field: "orderNumber",
      headerName: t("orderNumber"),
      minWidth: 150,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            textDecoration: "none",
            color: "inherit",
          }}
          onClick={() => {
            navigate("/order", {
              state: {
                orderId: params.row.id,
                studyId: params.row.studyId,
                centerNumber: params.row.centerNumber,
                centerId: params.row.centerId,
                studyCode: cdlUser
                  ? params.row.studyCode
                  : params.row.sponsorCode,
              },
            });
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: t("status"),
      flex: 2,
      minWidth: 150,
      filterable: true,
      renderCell: (params) => t(`statusValues.${params.value}`),
      filterOperators: [
        {
          label: t("status"),
          value: "status",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <CustomFilter
              label={t("status")}
              {...props}
              options={statusOptions}
            />
          ),
        },
      ],
    },
    {
      field: "orderDate",
      type: "date",
      headerName: t("orderDate"),
      minWidth: 150,
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {t("orderDate")}
          <br />
          <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>
        </div>
      ),
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return params.value ? moment(params.value).format("DD/MM/YYYY") : "";
      },
    },
    {
      field: "sendingDate",
      type: "date",
      headerName: t("sendingDate"),
      minWidth: 150,
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {t("sendingDate")}
          <br />
          <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>{" "}
        </div>
      ),
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return params.value
          ? moment(params.value).format("DD/MM/YYYY")
          : t("notSentYet");
      },
    },
    {
      field: "deliveryDate",
      type: "date",
      minWidth: 150,
      headerName: t("deliveryDate"),
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {t("deliveryDate")}
          <br />
          <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>{" "}
        </div>
      ),
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return params.value
          ? moment(params.value).format("DD/MM/YYYY")
          : t("notDeliveredYet");
      },
    },
    {
      field: "centerValidatedDate",
      minWidth: 150,
      type: "date",
      headerName: t("centerValidatedDate"),
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {t("centerValidatedDate")}
          <br />
          <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>
        </div>
      ),
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return params.value
          ? moment(params.value).format("DD/MM/YYYY")
          : t("notValidatedYet");
      },
    },
  ];
  if (!centerUser) {
    columns.push(
      {
        field: "processingTime",
        headerName: t("processingTime"),
        minWidth: 130,
        renderHeader: () => (
          <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
            {t("processingTime")}
          </div>
        ),
        valueGetter: (params) => calculateProcessingTime(params.row),
      },
      {
        field: "carrier",
        headerName: t("carrier"),
        minWidth: 130,
        filterable: true,
        renderCell: (params) => params.value,
        filterOperators: [
          {
            label: t("carrier"),
            value: "carrier",
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.value) {
                return null;
              }
              return ({ value }) => value === filterItem.value;
            },
            InputComponent: (props) => (
              <CustomFilter
                label={t("carrier")}
                options={carrierOptions}
                {...props}
              />
            ),
          },
        ],
      },
      {
        field: "packingSlip",
        headerName: t("airwaybill"),
        headerAlign: "left",
        minWidth: 130,
      },
      { field: "comment", headerName: t("comment"), minWidth: 300 }
    );
  }

  if (!centerId) {
    columns.unshift({
      field: "centerNumber",
      headerName: t("center"),
      flex: 1,
      minWidth: 100,
      filterable: true,
      renderCell: (params) => params.value,
      filterOperators: [
        {
          label: t("center"),
          value: "centerNumber",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <CustomFilter {...props} options={centerNumberOptions} />
          ),
        },
      ],
    });
  }

  if (!studyId && cdlUser) {
    columns.unshift({
      field: "studyCode",
      headerName: t("studyCode"),
      flex: 1,
      minWidth: 110,
      filterable: true,
      renderCell: (params) => params.value,
      filterOperators: [
        {
          label: t("studyCode"),
          value: "studyCode",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <CustomFilter {...props} options={studyCodeOptions} />
          ),
        },
      ],
    });
  }

  React.useEffect(() => {
    if (centerId) {
      store.dispatch(getOrdersbyCenter(centerId));
    } else if (studyId) {
      store.dispatch(getOrdersbyStudy(studyId));
    } else {
      store.dispatch(getOrders());
    }
  }, [studyId, centerId, updatedOrder]);

  // Map over the orders and format the dates
  React.useEffect(() => {
    if (orders.length > 0) {
      const ordersWithShipments = orders
        .filter((order) => order.Center.Study.cdlStudyCode !== "2021-07")
        .flatMap((order) => {
          if (order.shipments.length > 0) {
            return order.shipments.map((shipment, index) => {
              return {
                key: `${order.id}-${index}`,
                studyCode: order.Center?.Study?.cdlStudyCode,
                sponsorCode: order.Center?.Study?.sponsorStudyCode,
                id: order.id,
                studyId: order.Center?.Study?.id,
                centerId: order.centerId,
                orderNumber: order.orderNumber,
                status: shipment.status,
                isFirstSupply: order.isFirstSupply,
                orderDate: new Date(order.orderDate),
                centerNumber: order.Center.centerNumber,
                sendingDate: shipment ? new Date(shipment.sendingDate) : null,
                deliveryDate: shipment?.deliveryDate
                  ? new Date(shipment.deliveryDate)
                  : null,
                centerValidatedDate: shipment?.confirmedDeliveryDate
                  ? new Date(shipment.confirmedDeliveryDate)
                  : null,
                carrier: shipment?.carrier || null,
                packingSlip: shipment?.packingSlip || null,
                comment: order.comment,
              };
            });
          } else {
            return [
              {
                key: `${order.id}-0`,
                studyCode: order.Center?.Study?.cdlStudyCode,
                sponsorCode: order.Center?.Study?.sponsorStudyCode,
                id: order.id,
                studyId: order.Center?.Study?.id,
                centerId: order.centerId,
                orderNumber: order.orderNumber,
                status: order.status,
                isFirstSupply: order.isFirstSupply,
                orderDate: new Date(order.orderDate),
                centerNumber: order.Center.centerNumber,
                sendingDate: null,
                deliveryDate: null,
                centerValidatedDate: null,
                carrier: null,
                packingSlip: null,
                comment: order.comment,
              },
            ];
          }
        });

      setOrdersWithFormattedDates(ordersWithShipments);
    }
  }, [orders]);

  React.useEffect(() => {
    if (ordersWithFormattedDates.length > 0) {
      const statusTranslations = {
        cancelled: t("statusValues.cancelled"),
        confirmed: t("statusValues.confirmed"),
        delivered: t("statusValues.delivered"),
        lost: t("statusValues.lost"),
        "partially delivered": t("statusValues.partially delivered"),
        "partially shipped": t("statusValues.partially shipped"),
        placed: t("statusValues.placed"),
        received: t("statusValues.received"),
        shipped: t("statusValues.shipped"),
        "shipped 1/2": t("statusValues.shipped 1/2"),
        "delivered 1/2": t("statusValues.delivered 1/2"),
        "received 1/2": t("statusValues.received 1/2"),
      };
      setStatusOptions(
        getUniqueOptions(ordersWithFormattedDates, "status", statusTranslations)
      );
      setCarrierOptions(getUniqueOptions(ordersWithFormattedDates, "carrier"));
      setStudyCodeOptions(
        getUniqueOptions(ordersWithFormattedDates, "studyCode")
      );
      setCenterNumberOptions(
        getUniqueOptions(ordersWithFormattedDates, "centerNumber")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersWithFormattedDates]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "90vh" }}>
          <LocalizedDataGrid
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            rows={ordersWithFormattedDates}
            columns={columns}
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            getRowId={(row) => row.key}
            rowHeight={40}
            pageSizeOptions={[20, 50]}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              const selectedOrders = ordersWithFormattedDates
                .filter((order) => newSelection.includes(order.key))
                .map((order) => {
                  const filteredOrder = {};
                  columns.forEach((column) => {
                    if (column.field === "processingTime") {
                      filteredOrder[column.headerName] = calculateProcessingTime(order);
                    } else {
                      filteredOrder[column.headerName] = order[column.field];
                    }
                  });
                  return filteredOrder;
                });
              setSelectedRows(selectedOrders);
            }}
            onCellDoubleClick={(params) => {
              if (params.field === "processingTime" && cdlUser) {
                setIsFirstSupply(params.row.isFirstSupply);
                setFSDialogOpen(true);
                setSelectedOrder(params.row);
              }
            }}
          />
          <FirstSupplyDialog
            open={FSDialogOpen}
            handleClose={() => setFSDialogOpen(false)}
            isFirstSupply={isFirstSupply}
            setIsFirstSupply={setIsFirstSupply}
            handleFSConfirm={handleFSConfirm}
          />
        </div>
        {selectedRows.length > 0 && (
          <CSVDownloader
            data={selectedRows}
            filename={filename}
            name={t("selectedOrders")}
          />
        )}
      </Paper>
      {centerId && (
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => {
            navigate("/orders/new", {
              state: { centerId, studyId, centerNumber, studyCode },
            });
          }}
          style={{ position: "absolute", right: SPACING, bottom: SPACING }}
        >
          <AddIcon />
        </Fab>
      )}
    </Grid>
  );
};
