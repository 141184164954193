import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/en-gb';

dayjs.locale('en-gb');

const EditProductionDialog = ({
  open,
  onClose,
  selectedProduction,
  setBatch,
  setReference,
  setProductionDate,
  setExpirationDate,
  setSendingAuthorization,
  handleDeleteProduction,
  handleUpdateReference,
}) => {

  const { t } = useTranslation();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Dialog
      PaperProps={{
        style: { padding: '30px' },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {t("editProduction")} - {selectedProduction?.Material.name}
      </DialogTitle>

      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextField
              label={t("batch")}
              defaultValue={selectedProduction ? selectedProduction.batchNumber : ''}
              onChange={(e) => setBatch(e.target.value)}
              sx={{ margin: 2 }}
            />
            <TextField
              label={t("reference")}
              defaultValue={selectedProduction ? selectedProduction.reference : ''}
              onChange={(e) => setReference(e.target.value)}
              sx={{ margin: 2 }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextField
              label={t("productionDate")}
              defaultValue={
                selectedProduction ? formatDate(selectedProduction.productionDate) : ''
              }
              sx={{ margin: 2 }}
              disabled
            />
            <DatePicker
              label={t("newProductionDate")}
              value={
                selectedProduction ? dayjs(selectedProduction.productionDate) : null
              }
              format="DD/MM/YYYY"
              onChange={(date) => {
                setProductionDate(date ? date.format('YYYY-MM-DD') : null);
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextField
              label={t("expirationDate")}
              defaultValue={
                selectedProduction ? formatDate(selectedProduction.expirationDate) : ''
              }
              sx={{ margin: 2 }}
              disabled
            />
            <DatePicker
              id="date-picker"
              label={t("newExpirationDate")}
              value={
                selectedProduction ? dayjs(selectedProduction.expirationDate) : null
              }
              format="DD/MM/YYYY"
              onChange={(date) => {
                setExpirationDate(date ? date.format('YYYY-MM-DD') : null);
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextField
              label={t("sendingAuthorization")}
              defaultValue={
                selectedProduction ? formatDate(selectedProduction.sendingAuthorization) : ''
              }
              sx={{ margin: 2 }}
              disabled
            />
            <DatePicker
              label={t("newAuthorizationDate")}
              value={
                selectedProduction ? dayjs(selectedProduction.sendingAuthorization) : null
              }
              onChange={(date) => {
                setSendingAuthorization(date ? date.format('YYYY-MM-DD') : null);
              }}
              format="DD/MM/YYYY"
            />
          </div>
        </LocalizationProvider>
        <Button
          variant="contained"
          color="secondary"
          style={{ padding: '16px', margin: '16px' }}
          onClick={handleDeleteProduction}
        >
          {t("deleteProduction")}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          style={{ padding: '16px' }}
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ padding: '16px 32px', margin: '16px' }}
          onClick={handleUpdateReference}
        >
          {t("update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProductionDialog;