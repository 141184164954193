import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const LegalNotice = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <div style={{ margin: "20px 100px" }}>
      <Typography variant="h3" align="center" sx={{ color: theme.palette.primary.main }}>
        {t("legalNotice")}
      </Typography>
      <div>
        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
          {t("ownershipTitle")}
        </Typography>
        <Typography variant="body2">
          {t("ownershipContent")}
        </Typography>

        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
          {t("technicalInformationTitle")}
        </Typography>
        <Typography variant="body2">
          {t("technicalInformationContent")}
        </Typography>

        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
          {t("siteContentTitle")}
        </Typography>
        <Typography variant="body2">
          {t("siteContentContent")}
        </Typography>

        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
          {t("hypertextLinkTitle")}
        </Typography>
        <Typography variant="body2">
          {t("hypertextLinkContent")}
        </Typography>

        <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
          {t("authorizationToReproduceTitle")}
        </Typography>
        <Typography variant="body2">
          {t("authorizationToReproduceContent")}
        </Typography>
      </div>

      <Typography variant="h3" align="center" sx={{ color: theme.palette.primary.main, marginTop: "5rem" }}>
        {t("privacyPolicy")}
      </Typography>
      <Typography variant="body2">
        {t("privacyPolicyContent")}
      </Typography>
      <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
        {t("consentTitle")}
      </Typography>
      <Typography variant="body2">
        {t("consentContent")}
      </Typography>
      <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
        {t("informationWeCollectTitle")}
      </Typography>
      <Typography variant="body2">
        {t("informationWeCollectContent")}
      </Typography>
      <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
        {t("howWeUseYourInformationTitle")}
      </Typography>
      <Typography variant="body2">
        {t("howWeUseYourInformationContent")}
      </Typography>
      <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
        {t("logFilesTitle")}
      </Typography>
      <Typography variant="body2">
        {t("logFilesContent")}
      </Typography>
      <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
        {t("cookiesTitle")}
      </Typography>
      <Typography variant="body2">
        {t("cookiesContent")}
      </Typography>
      <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
        {t("gdprDataProtectionRightsTitle")}
      </Typography>
      <Typography variant="body2">
        {t("gdprDataProtectionRightsContent")}
      </Typography>
      <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
        {t("exerciseYourRightsTitle")}
      </Typography>
      <Typography variant="body2">
        {t("exerciseYourRightsContent")}
      </Typography>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
        >
          {t("home")}
        </Button>
      </div>
    </div>
  );
};

export default LegalNotice;