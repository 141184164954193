import React from 'react';
import { Button, Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng).then(() => {
      window.location.reload();
    });
  };

  return (
    <Box display="flex" justifyContent="center" gap={1} mb={1}>
      <Tooltip title={t('translateToEnglish')}>
        <Button
          variant={currentLanguage === 'en' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => changeLanguage('en')}
        >
          En
        </Button>
      </Tooltip>
      <Tooltip title={t('translateToFrench')}>
        <Button
          variant={currentLanguage === 'fr' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => changeLanguage('fr')}
        >
          Fr
        </Button>
      </Tooltip>
    </Box>
  );
};

export default LanguageSwitcher;