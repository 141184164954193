const AddressContactInfo = ({ order }) => {
  const addressParts = [
    order?.hospital,
    order?.ward,
    order?.building ? `Building: ${order.building}` : null,
    order?.street,
    order?.floor ? `Floor: ${order.floor}` : null,
    order?.zipcode,
    order?.shippingAddress,
  ];
  const filteredAddress = addressParts
    .filter((part) => part && part.trim() !== "")
    .join(", ");

  const contactParts = [
    order?.contactName,
    order?.contactPhone !== "NA" ? order?.contactPhone : null,
    order?.contactEmail,
  ];
  const filteredContact = contactParts
    .filter((part) => part && part.trim() !== "")
    .join(" - ");

  const contactLine = filteredContact ? `Contact: ${filteredContact}` : "";

  const fullAddress = contactLine
    ? `${filteredAddress}\n${contactLine}`
    : filteredAddress;

  return fullAddress;
};

export default AddressContactInfo;
