import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { ModalProduction } from "../../modals/Production";
import * as React from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import {
  getProductions,
  getProductionsByCenter,
  getExpiringProductions,
  updateProduction,
  deleteProduction,
} from "../../../redux/actions/production";
import { store } from "../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import CSVDownloader from "../../ui/CSVDownloader";
import {
  destroyProductions,
  updateProductionComment,
  attributeProduction,
  updateProductionStatus,
} from "../../../services/productionService";
import { getToday } from "../../ui/utils/DateUtils";
import dayjs from "dayjs";
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../../ui/utils/AlertUtils";
import "dayjs/locale/en-gb";
import EditProductionDialog from "../../ui/dialogs/EditProductionDialog";
import AttributeToPatientDialog from "../../ui/dialogs/AttributeToPatientDialog";
import TransferProductionDialog from "../../ui/dialogs/TransferProductionDialog";
import CustomFilter from "../../table/CustomFilter";
import getUniqueOptions from "../../table/getUniqueOptions";
import DestructionDateDialog from "../../ui/dialogs/DestructionDateDialog";
import { useTranslation } from "react-i18next";
import LocalizedDataGrid from "../../ui/datagrid/LocalizedDataGrid";

dayjs.locale("en-gb");

const SPACING = 50;

export const ScreenProductions = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const productions = useSelector((state) => state.production.productions);
  const cdlUser = useSelector(
    (state) => state.user.user.organization === "CDL"
  );
  const admin = useSelector((state) => state.user.user.role === "admin");
  const sponsorManager = useSelector(state => state.user.user.role === "manager"); 
  const employeeUser = useSelector(
    (state) =>
      state.user.user.organization === "CDL" &&
      state.user.user.role === "employee"
  );
  const centerUser = useSelector(
    (state) => state.user.user.organization === "Center"
  );
  const centers = useSelector((state) => state.center.centers);
  const reduxCenterId = useSelector((state) => state.user.user.centerId);
  const studyId = location.state?.studyId;
  const studyCode = location.state?.studyCode;
  const centerId = centerUser ? reduxCenterId : location.state?.centerId;
  const expiring = location.state?.expiring;
  const isLoading = useSelector(
    (state) => state.production.isLoadingProductions
  );
  const [visibleCreateEdit, setVisibleCreateEdit] = React.useState(false);
  const [sortedProductions, setSortedProductions] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [commentUpdated, setCommentUpdated] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false); // if patient number is empty
  const [showDateAlert, setShowDateAlert] = React.useState(false);
  const centerNumber = location.state?.centerNumber;
  const [destructionDate, setDestructionDate] = React.useState(dayjs());
  const [openDialog, setOpenDialog] = React.useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = React.useState(false);
  const [centerDialogOpen, setCenterDialogOpen] = React.useState(false);
  const [referenceDialogOpen, setReferenceDialogOpen] = React.useState(false);
  const [selectedProduction, setSelectedProduction] = React.useState(null);
  const [selectedCenterId, setSelectedCenterId] = React.useState("");
  const [selectedStatusRow, setSelectedStatusRow] = React.useState(null);
  const [productionDate, setProductionDate] = React.useState("");
  const [expirationDate, setExpirationDate] = React.useState("");
  const [batch, setBatch] = React.useState("");
  const [reference, setReference] = React.useState("");
  const [sendingAuthorization, setSendingAuthorization] = React.useState("");
  const [patientNumber, setPatientNumber] = React.useState("");
  const [attributionDate, setAttributionDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [centerOptions, setCenterOptions] = React.useState([]);
  const [materialOptions, setMaterialOptions] = React.useState([]);
  const [batchOptions, setBatchOptions] = React.useState([]);
  const [statusOptions, setStatusOptions] = React.useState([]);

  const statusTranslations = {
    "produced": t("produced"),
    "at center": t("atCenter"),
    "attributed to patient": t("attributedToPatient"),
    "in transit": t("inTransit"),
    "lost": t("lost"),
    "destroyed": t("destroyed"),
  };

  React.useEffect(() => {
    if (sortedProductions) {
      setCenterOptions(
        getUniqueOptions(sortedProductions, "Center.centerNumber", { "At CDL": t("atCDL") }, "At CDL")
      );
      setMaterialOptions(getUniqueOptions(sortedProductions, "Material.name"));
      setBatchOptions(getUniqueOptions(sortedProductions, "batchNumber"));
      setStatusOptions(getUniqueOptions(sortedProductions, "status", statusTranslations));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedProductions]);

  const columns = React.useMemo(() => {
    const baseColumns = [
      {
        field: "centerNumber",
        headerName: t("center"),
        valueGetter: (params) => {
          return params.row.Center ? params.row.Center.centerNumber : "At CDL";
        },
        renderCell: (params) => {
          return params.row.Center ? params.row.Center.centerNumber : t("atCDL");
        },
        filterable: true,
        filterOperators: [
          {
            label: t("center"),
            value: "centerNumber",
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.value) {
                return null;
              }
              return ({ value }) => value === filterItem.value;
            },
            InputComponent: (props) => (
              <CustomFilter label={t("center")} options={centerOptions} {...props} />
            ),
          },
        ],
      },
      {
        field: "batchNumber",
        headerName: t("batch"),
        width: 80,
        filterable: true,
        filterOperators: [
          {
            label: t("batch"),
            value: "batchNumber",
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.value) {
                return null;
              }
              return ({ value }) => value === filterItem.value;
            },
            InputComponent: (props) => (
              <CustomFilter label={t("batch")} options={batchOptions} {...props} />
            ),
          },
        ],
      },
      { field: "reference", headerName: t("reference"), width: 130 },
      {
        field: "materialName",
        headerName: t("materialType"),
        flex: 2,
        minWidth: 250,
        valueGetter: (params) => {
          return params.row.Material.name;
        },
        filterable: true,
        filterOperators: [
          {
            label: t("materialType"),
            value: "materialName",
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.value) {
                return null;
              }
              return ({ value }) => value === filterItem.value;
            },
            InputComponent: (props) => (
              <CustomFilter
                label={t("materialType")}
                options={materialOptions}
                {...props}
              />
            ),
          },
        ],
      },
      {
        field: "productionDate",
        type: "date",
        headerName: t("productionDate"),
        minWidth: 150,
        headerAlign: "left",
        renderHeader: () => (
          <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {t("productionDate")} <br />
            <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>
          </div>
        ),
        valueGetter: (params) => {
          return new Date(params.value);
        },
        renderCell: (params) => {
          return moment(params.value).format("DD/MM/YYYY");
        },
      },
      {
        field: "expirationDate",
        type: "date",
        headerName: t("expirationDate"),
        minWidth: 150,
        headerAlign: "left",
        renderHeader: () => (
          <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
            {t("expirationDate")}
            <br />
            <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>
          </div>
        ),
        valueGetter: (params) => {
          if (!cdlUser && params.row.status === "attributed to patient") {
            return new Date(); // return a default date when used
          } else {
            return new Date(params.value); // converts the value to a Date object
          }
        },
        renderCell: (params) => {
          const expirationDate = moment(params.value);
          const now = moment();
          const isExpired =
            (params.row.status === "at center" ||
              params.row.status === "produced") &&
            expirationDate.isBefore(now);
          const isExpiring =
            (params.row.status === "at center" ||
              params.row.status === "produced") &&
            expirationDate.isBefore(now.add(30, "days"));
          const dateFormat = employeeUser ? "MM/DD/YYYY" : "DD/MM/YYYY";
          return (
            <div
              style={
                isExpired
                  ? { color: "red" }
                  : isExpiring
                  ? { color: "darkorange" }
                  : {}
              }
            >
              {expirationDate.format(dateFormat)}
            </div>
          );
        },
      },
      {
        field: "status",
        headerName: t("status"),
        flex: 2,
        minWidth: 150,
        filterable: true,
        filterOperators: [
          {
            label: "Status",
            value: "status",
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.value) {
                return null;
              }
              return ({ value }) => value === filterItem.value;
            },
            InputComponent: (props) => (
              <CustomFilter label={t("Status")} options={statusOptions} {...props} />
            ),
          },
        ],
        valueGetter: (params) => {
          return t(params.value);
        },
      },
      {
        field: "sendingAuthorization",
        type: "date",
        headerName: t("sendingAuthorization"),
        headerAlign: "left",
        renderHeader: () => (
          <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
           {t("sendingAuthorization")}
            <br />
            <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>
          </div>
        ),
        valueGetter: (params) => {
          return new Date(params.value);
        },
        renderCell: (params) => {
          return moment(params.value).format("DD/MM/YYYY");
        },
      },
      {
        field: "Shipment.packingSlip",
        headerName: t("airwaybill"),
        flex: 1,
        minWidth: 150,
        valueGetter: (params) =>
          params.row.Shipment ? params.row.Shipment.packingSlip : "",
      },
      {
        field: "patientNumber",
        headerName: t("patientNumber"),
        flex: 1,
        minWidth: 150,
      },
      {
        field: "attributionDate",
        headerName: t("attributionDate"),
        headerAlign: "left",
        minWidth: 150,
        type: "date",
        renderHeader: () => (
          <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
            {t("attributionDate")} <br />
            <span style={{ fontSize: "x-small" }}> (DD/MM/YYYY)</span>
          </div>
        ),
        valueGetter: (params) => {
          return params.value ? new Date(params.value) : null;
        },
        renderCell: (params) => {
          if (params.value === null) {
            return null;
          }

          const attributionDate = moment(params.value);
          const expirationDate = moment(params.row.expirationDate);
          const isAfterExpiration = attributionDate.isAfter(expirationDate);
          return (
            <div style={isAfterExpiration ? { color: "red" } : {}}>
              {attributionDate.format("DD/MM/YYYY")}
            </div>
          );
        },
      },
      {
        field: "destructionDate",
        headerName: t("destructionDate") ,
        headerAlign: "left",
        type: "date",
        minWidth: 150,
        renderHeader: () => (
          <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          {t("destructionDate")}  <br />
            <span style={{ fontSize: "x-small" }}> (DD/MM/YYYY)</span>
          </div>
        ),
        valueGetter: (params) => {
          return params.value ? new Date(params.value) : null;
        },
        renderCell: (params) => {
          if (params.value === null) {
            return null;
          }

          const destructionDate = moment(params.value);
          return <div>{destructionDate.format("DD/MM/YYYY")}</div>;
        },
      },
      {
        field: "comment",
        headerName: t("comment"),
        flex: 1.3,
        minWidth: 200,
        editable: true,
      },
    ];

    let filteredColumns = baseColumns;

    if (centerId) {
      // If center, filter out the centerNumber column
      filteredColumns = filteredColumns.filter(
        (column) => column.field !== "centerNumber"
      );
    }

    if (centerUser) {
      // If centerUser, filter out columns
      filteredColumns = filteredColumns.filter(
        (column) =>
          column.field !== "batchNumber" &&
          column.field !== "productionDate" &&
          column.field !== "sendingAuthorization" &&
          column.field !== "comment" &&
          column.field !== "destructionDate" &&
          column.field !== "patientNumber" &&
          column.field !== "attributionDate" &&
          column.field !== "Shipment.packingSlip"
      );
    }
    return filteredColumns.map((column) => ({
      ...column,
      cellClassName: "my-datagrid-cell",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerId, centerUser, materialOptions, batchOptions]);

  // Destroy expiring productions
  const handleDestroyExpiringProductions = async () => {
    setOpenDialog(true);
  };

  const handleDateChange = async (date) => {
    setDestructionDate(date);
  };

  const handleConfirmDestruction = async () => {
    setOpenDialog(false);

    // After date selection, display the confirmation dialog
    Swal.fire({
      title: t("destroyProductionsTitle"),
      text: t("destroyProductionsText", { date: dayjs(destructionDate).format("YYYY-MM-DD") }),
      showCancelButton: true,
      confirmButtonColor: "#1e3179",
      confirmButtonText: t("confirmDestroy"),
      cancelButtonText: t("cancelDestroy"),
      reverseButtons: true,
    }).then(async (result) => {
      // If the user confirmed the action, destroy the productions
      if (result.isConfirmed) {
        try {
          await destroyProductions(selectedRows, destructionDate);
          showSuccessAlert(t("destroyProductionsSuccess"));
          setCommentUpdated(true);
        } catch (error) {
          console.error("Error destroying productions:", error);
        }
      }
    });
  };

  const handleCSVSelectedProductions = () => {
    const selectedProductions = productions.filter((production) =>
      selectedRows.includes(production.id)
    );

    const includedColumns = cdlUser
      ? columns.map((column) => column.field)
      : ["reference", "expirationDate", "materialName", "status"].concat(
          centerId ? [] : ["centerNumber"]
        );
    const visibleColumns = columns.filter((column) =>
      includedColumns.includes(column.field)
    );

    const printableData = selectedProductions.map((production) =>
      visibleColumns.reduce((obj, column) => {
        if (
          (column.field === "expirationDate" ||
            column.field === "destructionDate" ||
            column.field === "attributionDate" ||
            column.field === "productionDate" ||
            column.field === "sendingAuthorization") &&
          production[column.field] != null
        ) {
          obj[column.headerName] = new Date(production[column.field]);
        } else if (
          (column.field === "expirationDate" ||
            column.field === "destructionDate" ||
            column.field === "attributionDate" ||
            column.field === "productionDate" ||
            column.field === "sendingAuthorization") &&
          production[column.field] == null
        ) {
          obj[column.headerName] = "";
        } else {
          obj[column.headerName] = column.valueGetter
            ? column.valueGetter({ row: production })
            : production[column.field];
        }
        return obj;
      }, {})
    );

    return printableData;
  };

  const handleRowUpdate = (params) => {
    const updatedComment = params.comment;
    const productionId = params.id;

    // Get the original comment from the productions state variable
    const originalProduction = productions.find(
      (production) => production.id === productionId
    );
    const originalComment = originalProduction
      ? originalProduction.comment
      : null;

    // Only call updateProductionComment if the comment has changed
    if (originalComment !== updatedComment) {
      updateProductionComment(productionId, updatedComment)
        .then((data) => {
          if (data) {
            setCommentUpdated(true); //trigger the useEffect hook
            setShowSuccessMessage(true);

            // Hide the success message after 3 seconds
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          showErrorAlert("Failed to update comment");
        });
    }
  };

  const handleCellDoubleClick = (params) => {
    if (params.field === "status" && params.value === "at center" && cdlUser) {
      setSelectedStatusRow(params.row);
      setStatusDialogOpen(true);
    } else if (params.field === "centerNumber" && admin) {
      setSelectedProduction(params.row);
      setCenterDialogOpen(true);
    } else if (params.field === "reference" && admin) {
      setSelectedProduction(params.row);
      setReferenceDialogOpen(true);
    }
  };

  const handleTransferProduction = async () => {
    setCenterDialogOpen(false);
    const productionId = selectedProduction.id;
    const centerId = selectedCenterId;
    const status = "at center";
    try {
      await updateProductionStatus(productionId, status, centerId);
      showSuccessAlert("Production transfered to center successfully");
      setCommentUpdated(true);
    } catch (error) {
      console.error(error);
      showErrorAlert("Failed to transfer production to center");
    }
  };

  // Update reference
  const handleUpdateReference = async () => {
    setReferenceDialogOpen(false);
    const productionId = selectedProduction.id;
    try {
      const body = {};
      if (batch) body.batchNumber = batch;
      if (reference) body.reference = reference;
      if (productionDate) body.productionDate = productionDate;
      if (expirationDate) body.expirationDate = expirationDate;
      if (sendingAuthorization)
        body.sendingAuthorization = sendingAuthorization;
      await store.dispatch(updateProduction(productionId, body));
      showSuccessAlert("Production reference updated successfully");
      setCommentUpdated(true);
      handleCloseReferenceDialog();
    } catch (error) {
      showErrorAlert("Failed to update production reference");
    }
  };

  const handleCloseReferenceDialog = () => {
    setReferenceDialogOpen(false);
    setSelectedProduction(null);
    setReference("");
    setBatch("");
    setProductionDate("");
    setExpirationDate("");
    setSendingAuthorization("");
  };

  const handleDeleteProduction = async () => {
    setReferenceDialogOpen(false);
    const productionId = selectedProduction.id;
    try {
      const result = await showWarningAlert(
        t("deleteProductionWarning")
      );
      if (result.isConfirmed) {
        await store.dispatch(deleteProduction(productionId));
        showSuccessAlert(t("deleteProductionSuccess"));
        setCommentUpdated(true);
      }
    } catch (error) {
      console.error(error);
      showErrorAlert(t("deleteProductionFailed"));
    }
  };

  const handleCloseStatusDialog = () => {
    setStatusDialogOpen(false);
    setSelectedStatusRow(null);
    setPatientNumber("");
    setAttributionDate(dayjs().format("YYYY-MM-DD"));
  };

  const handleStatusFormSubmit = async (patientNumber, attributionDate) => {
    if (selectedStatusRow && patientNumber !== "") {
      const productionId = selectedStatusRow.id;
      handleCloseStatusDialog();
      setShowAlert(false);

      const result = await showWarningAlert(
         t("attributeProductionWarning")
      );
      if (result.isConfirmed) {
        try {
          await attributeProduction(
            productionId,
            attributionDate,
            patientNumber
          );
          showSuccessAlert(t("attributeProductionSuccess"));
          setCommentUpdated(true);
        } catch (error) {
          console.error(error);
          showErrorAlert(t("attributeProductionFailed"));
        }
      }
    } else if (patientNumber === "") {
      setShowAlert(true);
    }
  };

  // Fetch productions
  React.useEffect(() => {
    const fetchData = async () => {
      if (expiring) {
        await store.dispatch(getExpiringProductions(centerId));
      } else if (centerId) {
        await store.dispatch(getProductionsByCenter(centerId));
      } else if (studyId) {
        await store.dispatch(getProductions(studyId));
      } else {
        navigate("/studies");
      }
    };
    fetchData();
    setCommentUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId, centerId, expiring, commentUpdated]);

  // Sort productions and filter them based on user type
  React.useEffect(() => {
    if (productions) {
      // if not cdlUser, remove from productions all that have centerId null
      let sorted;
      if (!cdlUser && !sponsorManager) {
        sorted = productions.filter(
          (production) => production.centerId !== null
        );
      } else {
        sorted = [...productions];
      }

      const statusOrder = {
        "produced": 1,
        "at center": 2,
        "attributed to patient": 3,
        "in transit": 4,
        "destroyed": 5,
      };

      sorted.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
      setSortedProductions(sorted);
    }
  }, [cdlUser, productions]);

  React.useEffect(() => {
    if (
      selectedStatusRow !== null &&
      dayjs().isAfter(dayjs(selectedStatusRow.expirationDate))
    ) {
      setShowDateAlert(true);
    } else {
      setShowDateAlert(false);
    }
  }, [selectedStatusRow]);

  // to assign center to production
  React.useEffect(() => {
    setSelectedCenterId(selectedProduction?.centerId || "");
  }, [selectedProduction]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          height: "100%",
          overflow: "auto",
        }}
      >
        <div style={{ width: "100%", height: "85vh" }}>
          <LocalizedDataGrid
            autoWidth
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            disableColumnResize={false}
            rows={sortedProductions}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            pageSizeOptions={[20, 50, 100]}
            rowHeight={30}
            checkboxSelection
            processRowUpdate={handleRowUpdate}
            onRowSelectionModelChange={(selection) => {
              setSelectedRows(selection);
            }}
            onCellDoubleClick={handleCellDoubleClick}
            sx={{
              "& .my-datagrid-cell": {
                fontSize: "0.75rem",
              },
            }}
          />
        </div>
        {/*  */}
        {/* EDIT OR DELETE THE PRODUCTION */}
        {/*  */}
        <EditProductionDialog
          open={referenceDialogOpen}
          onClose={handleCloseReferenceDialog}
          selectedProduction={selectedProduction}
          setBatch={setBatch}
          setReference={setReference}
          setProductionDate={setProductionDate}
          setExpirationDate={setExpirationDate}
          setSendingAuthorization={setSendingAuthorization}
          handleDeleteProduction={handleDeleteProduction}
          handleUpdateReference={handleUpdateReference}
        />
        {/*  */}
        {/* ATTRIBUTE A REFERENCE TO A PATIENT */}
        {/*  */}
        <AttributeToPatientDialog
          open={statusDialogOpen}
          onClose={handleCloseStatusDialog}
          selectedStatusRow={selectedStatusRow}
          patientNumber={patientNumber}
          setPatientNumber={setPatientNumber}
          attributionDate={attributionDate}
          setAttributionDate={setAttributionDate}
          showAlert={showAlert}
          showDateAlert={showDateAlert}
          handleStatusFormSubmit={handleStatusFormSubmit}
          setShowDateAlert={setShowDateAlert}
        />
        {/*  */}
        {/* TRANSFER PRODUCTION TO A CENTER */}
        {/*  */}
        <TransferProductionDialog
          open={centerDialogOpen}
          onClose={() => setCenterDialogOpen(false)}
          selectedProduction={selectedProduction}
          selectedCenterId={selectedCenterId}
          setSelectedCenterId={setSelectedCenterId}
          centers={centers}
          handleTransferProduction={handleTransferProduction}
        />
        {showSuccessMessage && (
          <div
            style={{
              color: "#155724",
              backgroundColor: "#d4edda",
              borderColor: "#c3e6cb",
              padding: "0.75em",
              marginBottom: "1em",
              border: "1px solid transparent",
              borderRadius: ".25em",
            }}
          >
            {t("productionCommentUpdated")}
          </div>
        )}
        {selectedRows.length > 0 && (
          <Grid container justifyContent="space-around" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                onClick={handleDestroyExpiringProductions}
                variant="contained"
                color="secondary"
                style={{ padding: "16px 8px", margin: "16px" }}
                fullWidth
              >
                {t("destroySelectedProductions")}
              </Button>
              <DestructionDateDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                destructionDate={destructionDate}
                handleDateChange={handleDateChange}
                handleConfirmDestruction={handleConfirmDestruction}
              />
            </Grid>
            <CSVDownloader
              data={handleCSVSelectedProductions()}
              filename={`${studyCode}${
                centerNumber ? `_${centerNumber}` : ""
              }_productions_${getToday()}`}
              name={t("selectedProductions")}
            />
          </Grid>
        )}
      </Paper>
      {cdlUser && !centerId && !employeeUser && (
        <>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setVisibleCreateEdit(true);
            }}
            style={{ position: "absolute", right: SPACING, bottom: SPACING }}
          >
            <AddIcon />
          </Fab>
          <ModalProduction
            visible={visibleCreateEdit}
            studyId={studyId}
            studyCode={studyCode}
            onClose={(refresh) => {
              setVisibleCreateEdit(false);
              if (refresh) {
                store.dispatch(getProductions(studyId));
              }
            }}
            productions={productions}
          ></ModalProduction>
        </>
      )}
    </Grid>
  );
};
